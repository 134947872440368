<template>
    <div>
        <a-spin :spinning="loading">
            <a-table
                :columns="colums"
                :dataSource="data"
                bordered
                :pagination="false">
                <template slot="title">
                    <a-input-search style="width:250px;" v-model="params.keywords" placeholder="输入客户姓名搜索" enter-button="搜索" @search="page()"/>
                    <a-button icon="plus" type="primary" style="margin-left: 10px;" @click="$refs.saveCustomer.showModal(null)">添加客户</a-button>
                </template>
                <template slot="type" slot-scope="text">
                    <a-tag v-if="text === 0">
                        还未确认
                    </a-tag>
                    <a-tag v-if="text === 1" color="green">
                        有房出售
                    </a-tag>
                    <a-tag v-if="text === 2" color="cyan">
                        有房出租
                    </a-tag>
                    <a-tag v-if="text === 3" color="blue">
                        我要买房
                    </a-tag>
                    <a-tag v-if="text === 4" color="purple">
                        我要租房
                    </a-tag>
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button size="small" type="primary" class="action" @click="$refs.saveCustomer.showModal(record)">修改</a-button>
                    <a-button size="small" type="primary" icon="edit" class="action" @click="$refs.contactModal.showModal(record.id, 2)">跟进情况</a-button>
                    <a-popconfirm
                        title="确认删除吗？"
                        ok-text="确认"
                        cancel-text="取消"
                        @confirm="del(record.id)"
                        >
                        <a-button size="small" type="info" class="action">删除</a-button>
                    </a-popconfirm>
                    <span v-if="record.isNew === 1" style="color:red;">新<a-icon type="pushpin" /></span>
                </template>
            </a-table>
            <a-pagination
                v-if="data.length > 0"
                showQuickJumper
                style="margin-top:10px;"
                :defaultCurrent="1"
                :total="total"
                :pageSize="params.pageSize"
                @change="pageChange"
            />
        </a-spin>
        <SaveCustomer ref="saveCustomer" @ok="page"/>
        <ContactModal ref="contactModal" @ok="page"/>
    </div>
</template>
<script>
import SaveCustomer from './modules/SaveCustomer'
import ContactModal from '@/components/ContactModal'
const colums = [
    { title: '客户姓名', dataIndex: 'name'},
    { title: '联系电话', dataIndex: 'mobile'},
    { title: '客户来源', dataIndex: 'csource'},
    { title: '小区', dataIndex: 'village'},
    { title: '门牌号', dataIndex: 'number'},
    { title: '具体地址', dataIndex: 'address'},
    { title: '期望售价', dataIndex: 'money'},
    { title: '类型', dataIndex: 'type', scopedSlots: { customRender: 'type' }},
    { title: '操作', scopedSlots: { customRender: 'action' } }
]
export default {
    components: {
        SaveCustomer,
        ContactModal
    },
    data () {
        return {
            loading: false,
            data: [],
            colums,
            total: 0,
            params: {
                pageNum: 1,
                pageSize: 10,
                keywords: null
            }
        }
    },
    mounted () {
        this.page()
    },
    methods: {
        page () {
            let keywords = -1
            if (this.params.keywords !== '' && this.params.keywords !== null ) {
                keywords = this.params.keywords
            }
            this.loading = true
            this.$api.customer.page(this.params.pageNum, this.params.pageSize, keywords).then(res => {
                if (res.status === 0) {
                    this.data = res.data
                    this.total = res.total
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        del (id) {
            this.$api.customer.del(id).then(res => {
                if (res.status === 0) {
                    this.$message.success('成功')
                    this.page()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        pageChange (pageNum) {
            this.params.pageNum = pageNum
            this.page()
        }
    }
}
</script>
<style scoped>
    .action {
        margin-right: 10px;
    }
</style>

